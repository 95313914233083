<template>
  <div class="addActivity">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入"></v-button> -->
        <v-button text="新增" @click="toAdd"></v-button>
        <!-- <v-button text="导出"></v-button> -->
      </template>
      <template #searchSlot>
        <!-- <community-select v-model="searchParam.communityId" label="选择园区" /> -->
        <v-input label="活动名称" v-model="searchParam.activityName" />
        <v-select
          clearable
          :options="activityStatus"
          v-model="searchParam.activityStatus"
          @change="$refs.list.search()"
          label="活动状态"
        />
        <v-select
          clearable
          :options="activityTypeOptions"
          v-model="searchParam.activityType"
          @change="$refs.list.search()"
          label="活动发起"
        />
        <v-select
          clearable
          :options="activityCategoryOptions"
          v-model="searchParam.activityCategory"
          @change="$refs.list.search()"
          label="活动类型"
        />
        <v-select
          clearable
          :options="auditStatusOptionsAll"
          v-model="searchParam.auditStatus"
          @change="$refs.list.search()"
          label="审核状态"
        />
      </template>
      <template #operateSlot="scope">
        <!-- <v-button
          text="活动二维码"
          type="text"
          v-if="
            scope.row.activityQrCodeUrl &&
            scope.row.activityQrCodeUrl.length > 0
          "
          @click="showACQrCode(scope.row.activityQrCodeUrl)"
        />
        <v-button
          text="签到二维码"
          type="text"
          @click="showQrCode(scope.row)"
        />

        <v-button
          text="活动回顾"
          type="text"
          v-if="scope.row.activityStatus == 5"
          @click="toEditFeedback(scope.row)"
        /> -->
        <v-button text="查看" type="text" @click="toEdit(scope.row)" />
        <!-- <v-button
          text="审核"
          type="text"
          v-if="scope.row.activityType == 0 && scope.row.auditStatus == 0"
          @click="toAudit(scope.row)"
        /> -->
        <!-- <v-button
          text="取消"
          type="text"
          v-if="scope.row.activityStatus != 6"
          @click="toCancel(scope.row)"
        /> -->
      </template>
    </v-list>

    <el-dialog
      title="审核"
      :visible.sync="auditDialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form label-width="100px">
        <el-form-item label="活动标题">
          <v-input v-model="tempObj.activityName" :width="400" disabled />
        </el-form-item>
        <el-form-item label="活动地址">
          <v-input v-model="tempObj.activityAddress" :width="400" disabled />
        </el-form-item>
        <el-form-item label="活动图片">
          <v-upload :imgUrls.sync="tempObj.activityPicture" disabled />
        </el-form-item>
        <el-form-item label="发布者">
          <v-input v-model="tempObj.publisherName" :width="400" disabled />
        </el-form-item>
        <el-form-item label="发布时间">
          <v-input v-model="tempObj.publishTime" :width="400" disabled />
        </el-form-item>
        <el-form-item label="审核结果">
          <v-select
            :options="auditStatusOptions"
            v-model="tempObj.auditStatus"
          />
        </el-form-item>
        <el-form-item label="奖励积分" v-if="tempObj.auditStatus === '1'">
          <v-input v-model="tempObj.bonusPoints" type="number" />
        </el-form-item>
        <el-form-item label="备注信息" v-if="tempObj.auditStatus === '1'">
          <v-input
            v-model="tempObj.auditRemarks"
            :width="400"
            :maxlength="100"
          />
        </el-form-item>
      </el-form>
      <!--      <div slot="footer" class="dialog-footer">-->
      <!--        <el-button @click="auditDialogFormVisible = false">取 消</el-button>-->
      <!--        <el-button type="primary" @click="doAudit">确 定</el-button>-->
      <!--      </div>-->
      <div slot="footer" class="dialog-footer dialogBottomBtn">
        <v-button
          size="medium"
          @click="auditDialogFormVisible = false"
          text="取消"
        ></v-button>
        <v-button
          size="medium"
          type="primary"
          @click="doAudit"
          text="确定"
        ></v-button>
      </div>
    </el-dialog>
    <v-dialog
      title="场次积分二维码"
      class="realNameAuditForm-addAddress"
      v-model="isdialog"
      @confirm="confirm"
      cancelTxt="取消"
      sureTxt="关闭"
    >
      <div class="img" v-for="(item, index) in scheduleList" :key="index">
        <div class="title">
          {{ item.pointsQrCodeUrl ? "场次" + (index + 1) : "无" }}
        </div>
        <img :src="item.pointsQrCodeUrl" alt="" />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  getActivityListUrl,
  activityCancel,
  activityAudit,
  donateAudit,
  myPublishActivityUrl,
} from "./api.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
import { activityStatus } from "./map.js";
// import { donateAudit } from "@/views/neighborhood/donateManage/api";

export default {
  name: "talentActivities",
  data() {
    return {
      scheduleList: [],
      auditDialogFormVisible: false,
      isdialog: false,
      tempObj: {
        activityId: null,
        activityName: null,
        activityAddress: null,
        activityPicture: null,
        publisherName: null,
        publishTime: null,
        publisherPic: null,
        auditStatus: null,
        auditRemarks: null,
        bonusPoints: 0,
      },
      auditStatusOptions: [
        { value: "1", label: "审核通过" },
        { value: "2", label: "审核驳回" },
      ],
      auditStatusOptionsAll: [
        { value: "0", label: "待审核" },
        { value: "1", label: "审核通过" },
        { value: "2", label: "审核驳回" },
      ],
      activityTypeOptions: [
        { value: "0", label: "业主发起" },
        { value: "1", label: "管理后台" },
      ],
      activityCategoryOptions: [
        { value: 1, label: "热门活动" },
        { value: 20, label: "时间银行" },
        { value: 19, label: "幸福学堂" },
      ],
      activityStatus: activityStatus,
      searchParam: {
        communityId: null,
        activityName: null,
        activityStatus: null,
        auditStatus: null,
        activityType: null,
        activityCategory: undefined,
        userId: this.$store.state.app.userInfo.userId,
      },
      tableUrl: myPublishActivityUrl,
      headers: [
        // {
        //   prop: "communityName",
        //   label: "园区名称",
        // },
        // {
        //   prop: "activityId",
        //   label: "活动Id",
        // },
        {
          prop: "activityName",
          label: "活动名称",
        },
        {
          prop: "activityAddress",
          label: "活动地点",
        },
        {
          prop: "activityPicture",
          label: "活动图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "limitCount",
          label: "限制人数",
        },
        // {
        //   prop: "applyUserCount",
        //   label: "活动参与人数",
        //   align: "center",
        //   formatter: (row, prop) =>
        //     createAlinkVNode(this, row, prop, {
        //       text: row.applyUserCount,
        //       cb: () => this.toApplicationsList(row),
        //     }),
        // },
        // {
        //   prop: "activityStatus",
        //   label: "活动状态",
        //   formatter: (row, prop) => {
        //     for (let i = 0; i < activityStatus.length; i++) {
        //       if (row.activityStatus === Number(activityStatus[i].value)) {
        //         return activityStatus[i].label;
        //       }
        //     }
        //   },
        // },
        {
          prop: "applyStartDate",
          label: "报名开始时间",
        },
        {
          prop: "applyEndDate",
          label: "报名结束时间",
        },
        {
          prop: "startDate",
          label: "活动开始时间",
        },
        {
          prop: "endDate",
          label: "活动结束时间",
        },
        {
          prop: "auditStatus",
          label: "审核状态",
          formatter: (row, prop) => {
            if (row.auditStatus == 0) {
              return "待审核";
            } else if (row.auditStatus == 1) {
              return "审核通过";
            } else if (row.auditStatus == 2) {
              return "审核驳回";
            }
          },
        },
      ],
    };
  },
  components: {},
  mounted() {},
  computed: {},
  methods: {
    confirm() {
      this.isdialog = false;
    },
    toAudit(data) {
      this.auditDialogFormVisible = true;
      this.tempObj = {
        activityId: data.activityId,
        activityName: data.activityName,
        activityAddress: data.activityAddress,
        activityPicture: data.activityPicture,
        publisherName: data.publisherName,
        publishTime: data.publishTime,
        publisherPic: data.publisherPic,
        auditStatus: null,
        auditRemarks: null,
        bonusPoints: 0,
      };
    },

    doAudit() {
      if (!this.tempObj.auditStatus) {
        this.$message.error("请选择审核结果！");
        return;
      }
      if (this.tempObj.bonusPoints && this.tempObj.bonusPoints < 0) {
        this.$message.error("奖励积分不能小于0！");
        return;
      }
      this.$axios
        .post(`${activityAudit}`, null, {
          params: {
            activityId: this.tempObj.activityId,
            auditStatus: this.tempObj.auditStatus,
            auditRemarks: this.tempObj.auditRemarks,
            bonusPoints: this.tempObj.bonusPoints,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.auditDialogFormVisible = false;
            this.$message.success("操作成功");

            this.$refs.list.search();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    showACQrCode(url) {
      window.open(url);
    },
    showQrCode(row) {
      // 活动二维码
      if (row.scheduleList) {
        this.scheduleList = row.scheduleList;
        this.isdialog = true;
      } else {
        window.open(row.pointsQrCodeUrl);
      }
    },
    toAdd() {
      this.$router.push({
        name: "talentActivitiesForm",
      });
    },
    toEditFeedback(data) {
      this.$router.push({
        name: "activityFeedback",
        query: { activityId: data.activityId, communityId: data.communityId },
      });
    },
    toEdit(data) {
      this.$router.push({
        name: "talentActivitiesForm",
        query: { applyId: data.applyId, communityId: data.communityId },
      });
    },
    toCancel(data) {
      let message = "确定要取消该活动 " + data.activityName;
      if (data.applyUserCount > 0) {
        message = "当前已经报名 " + data.applyUserCount + ", " + message;
      }

      this.$confirm(message, "确定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post(`${activityCancel}`, null, {
              params: {
                activityId: data.activityId,
              },
            })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("取消成功");
                this.$refs.list.search();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(() => {
          // Message.error('禁用失败！');
        });
    },
    toApplicationsList(data) {
      this.$router.push({
        name: "activityApplicants",
        query: {
          id: data.activityId,
          cid: data.communityId,
          name: data.activityName,
          type: data.activityCategory,
          scheduleId: data.scheduleList ? data.scheduleList[0].id : "",
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.addActivity {
  box-sizing: border-box;
  height: 100%;
  .realNameAuditForm-addAddress {
    /deep/ .el-dialog__body {
      max-height: 600px;
      overflow: auto;
      .img {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
